<template>
    <div>
        <div class="pa-6">
            <h6 class="text-h6 mb-3">API Tokens</h6>
            <p class="text-body-1">
                Your API tokens need to be treated as securely as any other password. You can only create a maximum of 25 tokens at a time. For more information on how to use our API, please refer to our <a href="https://docs.voiceform.com/reference/authentication" target="_blank">API documentation.</a>
            </p>
            <div v-if="accessTokens && accessTokens.length">
                <v-row>
                    <v-col class="text-subtitle-1">
                        Labels
                    </v-col>
                    <v-col class="text-subtitle-1">
                        Last Access
                    </v-col>
                    <v-col class="text-subtitle-1">
                        Action
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="token in accessTokens" :key="token.id">
                    <v-row>
                        <v-col class="text-body-1 pt-4">
                            {{token.name}}
                        </v-col>
                        <v-col class="text-body-1 pt-4">
                            {{lastAccess(token.last_used_at)}}
                        </v-col>
                        <v-col class="text-body-1">
                            <v-btn class="mx-n4" text color="primary" @click="handleRevokeClick(token)">Revoke</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </div>
            </div>
            <v-btn class="mt-6" elevation="0" color="primary" @click="openCreateTokenDialog">
                Create API token
            </v-btn>
        </div>
        <v-dialog
            v-model="tokenCreateDialog"
            width="600"
            @click:outside="closeCreateTokenDialog"

        >
            <v-card v-if="!createdToken">
                <v-card-title >
                    Create an API token
                </v-card-title>
                <v-card-text>
                    <p class="text-body-1 mt-2">Choose a label that is short, memorable, and easy for you to remember.</p>
                    <v-text-field counter="30" class="mt-6" autofocus outlined label="Label"  v-model="tokenLabel"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="closeCreateTokenDialog"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :elevation="0"
                        @click="createToken"
                        :loading="createTokenLoading"
                        :disabled="createTokenLoading"
                    >
                        Create
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-else>
                <v-card-title>
                    Your new API token
                </v-card-title>
                <v-card-text>
                    <p class="text-body-1 mt-2">Make sure you copy your new API token. You won't be able to see this token again.</p>
                    <v-text-field 
                        :append-icon="showToken ? 'mdi-eye' : 'mdi-eye-off'"
                        ref="tokenInput"  
                        class="mt-6" 
                        outlined 
                        readonly
                        :value="createdToken"
                        :type="showToken ? 'text' : 'password'"
                        @click:append="showToken = !showToken"
                        v-on:focus="handleTokenInputFocus" 
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="closeCreateTokenDialog"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :elevation="0"
                        @click="copyToken"
                    >
                        Copy
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="revokeDialog"
                width="500"
                @click:outside="closeRevokeDialog"
            >


            <v-card class="remove-dialog pt-4 pb-3 px-6">
                <div class="text-h6">
                    Revoke Token
                </div>
                <p>Are you sure you want to revoke <b>{{revokingToken && revokingToken.name}}</b> token? Once revoked, you will not be able to recover this token.</p>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="closeRevokeDialog"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    :elevation="0"
                    @click="revokeToken"
                    :loading="revokeLoading"
                    :disabled="revokeLoading"
                >
                    Yes, Revoke it
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ApiTokens",
    data() {
        return {
            loadingTokens: false,
            tokenCreateDialog: false,
            createTokenLoading: false,
            tokenLabel: "",
            createdToken: "",
            showToken: false,
            revokeDialog: false,
            revokingToken: null,
            revokeLoading: false
        };
    },

    watch: {
        tokenLabel(value){
            return this.tokenLabel = value.toLowerCase();
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            accessTokens: "auth/accessTokens"
        }),
    },
    methods: {
        ...mapActions({
            setUser: "auth/setUser",
            setTokens: "auth/setApiTokens",
            revokeTokenAction: "auth/revokeApiToken",
            createApiToken:'auth/createApiToken',
        }),
        lastAccess(date){

            return date? this.$date(date).format('YYYY-MM-DD'): 'Never Accessed';
        },
        openCreateTokenDialog(){
            this.tokenCreateDialog = true;
        },
        closeCreateTokenDialog(){
            this.tokenLabel= ""
            this.createdToken= ""
            this.showToken= false
            this.tokenCreateDialog = false;
        },
        async createToken(){
            try {
                this.createTokenLoading = true;
                const token = await this.createApiToken(this.tokenLabel)
                this.createdToken = token;
                this.createTokenLoading = false;
            } catch (error) {
                this.createTokenLoading = false;
            }
        },
        async copyToken(){
            try {
                this.$refs.tokenInput.focus();
                await navigator.clipboard.writeText(this.createdToken)
            } catch (error) {
                console.error("Cannot copy token", error);
            }
        },
        handleTokenInputFocus(event){
            event.target.select()
            navigator.clipboard.writeText(this.createdToken)
        },

        handleRevokeClick(token){
            this.revokeDialog = true;
            this.revokingToken = token;
        },
        closeRevokeDialog(){
            this.revokeDialog = false
            this.revokingToken = null
            this.revokeLoading = false
        },
        async revokeToken(){
            try {
                if(this.revokingToken){
                    this.revokeLoading = true
                    await this.revokeTokenAction(this.revokingToken.id)
                    this.revokeLoading = false
                    this.closeRevokeDialog()
                }
            } catch (error) {
                this.revokeLoading = false;
            }
        }
        
    },
    async mounted(){
        this.loadingTokens = true;
        await this.setTokens();
        this.loadingTokens = false;
    }
};
</script>