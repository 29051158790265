<template>
    <div v-if="user">
        <div class="d-flex text-md-h6 mb-6">
            Profile Info
            <v-spacer></v-spacer>
            <v-btn outlined @click="editing=true">Change</v-btn>
        </div>

        <div class="d-flex mb-5">
            <div class="text-subtitle-1 mr-4 grey--text text--darken1" style="min-width: 100px">First Name</div>
            <div class="text-body-1">{{user.first_name || '--'}}</div>
        </div>

        <div class="d-flex mb-5">
            <div class="text-subtitle-1 mr-4 grey--text text--darken1" style="min-width: 100px">Last Name</div>
            <div class="text-body-1">{{user.last_name || '--'}}</div>
        </div>

        <div class="d-flex mb-5">
            <div class="text-subtitle-1 mr-4 grey--text text--darken1" style="min-width: 100px">Email</div>
            <div class="text-body-1">{{user.email || '--'}}</div>
        </div>

        <v-alert
            v-if="!user.email_verified_at"
            class="mt-4"
            outlined
            type="error"
            dense
        >
            <div class="text-subtitle-1 mb-4">
                Please verify your email.
            </div>
            <div class="body-1 mb-4">
                We've sent an email to <b>{{user.email}}</b> to verify your email address. The email link will expire in 1 hour.
            </div>
            <div class="d-flex black--text text-body-2">
                Still can't find the email?&nbsp;<a @click="sendVerification">Click here</a>&nbsp;to resend.
            </div>
        </v-alert>

        <div class="d-flex mb-5" v-if="!user.sso_enabled">
            <div class="text-subtitle-1 mr-4 grey--text text--darken1" style="min-width: 100px">Password</div>
            <div class="text-body-1">--</div>
        </div>
        <v-dialog
            v-model="editing"
            width="600"
            @click:outside="editing=false"
        >
            <v-card class="pa-6">
                <div class="text-h6 mb-6">
                    Update Profile Info
                </div>

                <v-text-field 
                    outlined 
                    dense 
                    label="First name" 
                    v-model="user_data.first_name"
                    :error-messages="errors['first_name']"
                    class="mb-5"
                    validate-on-blur
                    hide-details="auto"
                ></v-text-field>
                <v-text-field 
                    outlined 
                    dense 
                    label="Last name" 
                    v-model="user_data.last_name"
                    :error-messages="errors['last_name']"
                    class="mb-5"
                    validate-on-blur
                    hide-details="auto"
                ></v-text-field>
                <v-text-field 
                    outlined 
                    dense 
                    label="Email" 
                    v-model="user_data.email"
                    :error-messages="errors['email']"
                    type="email"
                    :disabled="!!user.sso_enabled"
                    class="mb-5"
                    validate-on-blur
                    hide-details="auto"
                ></v-text-field>
                <v-text-field 
                    v-if="!user.sso_enabled"
                    outlined 
                    dense 
                    label="Password" 
                    v-model="user_data.password"
                    :error-messages="errors['password']"
                    type="password"
                    class="mb-5"
                    validate-on-blur
                    hide-details="auto"
                ></v-text-field>
                <v-card-actions class="pa-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="editing=false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        :elevation="0"
                        @click="updateUser"
                    >
                        Update
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import axios from 'axios'
import Api from '../../../api/Api';
export default {
    data(){
        return {
            editing: false,
            errors: {},
            updating_profile: false,
            user_data: {
                first_name: '',
                last_name: '',
                email: '',
                password: ''
            }
        };
    },
    watch: {
        user: {
            deep: true,
            handler(val) {
                this.user_data = {...val}
            },
        },

        user_data:{
            deep: true,
            handler(val){
                if (val.first_name) {
                    this.errors["first_name"] = [];
                }
                if (val.last_name) {
                    this.errors["last_name"] = [];
                }
                if (val.email) {
                    this.errors["email"] = [];
                }
                if (val.password) {
                    this.errors["password"] = [];
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        })
    },
    methods: {
        ...mapActions({
            setUser: "auth/setUser",
        }),

        ...mapMutations(["showSnackBar"]),
        async updateUser() {
            try {
                this.updating_profile = true
                const res = await axios.post(
                    "/api/users/auth/update-info",
                    this.user_data
                );
                this.user_data.password = '';
                if (res.data.errors) {
                    this.errors = res.data.errors;
                }

                if(res.data && res.data.user_info_updated){
                    this.showSnackBar({
                        color: "success",
                        timeout: 3000,
                        text: `Information is updated`,
                    });
                    await this.setUser()
                    this.updating_profile = false
                    this.editing=false;
                }
            } catch (error) {
                this.updating_profile = false
                 this.showSnackBar({
                        color: "Error",
                        timeout: 3000,
                        text: `Can't update profile information`,
                    });
            }
        },
        

        async sendVerification(){
            try {
                const res = await Api.sendEmailVerification()
                if(res && res.data){
                    if(res.data.status === 'sent'){
                        this.showSnackBar({
                            color: "success",
                            timeout: 6000,
                            text: `The email has been sent to ${this.user.email}`,
                        });
                    }
                }
            } catch (error) {
                let text = 'Can\'t send email. Please try again later.'
                if(error.response && error.response.data && error.response.data.error){
                    text = error.response.data.error
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text,
                });
            }
            
        }
    },
    async mounted() {
        await this.setUser();

        if(this.user && !this.user.email_verified_at && (!this.user.email_verification_sent_at || this.$date(this.user.email_verification_sent_at).diff(this.$date(), 'hour', true)>1)){
            this.sendVerification()
        }
    }
}
</script>