<template>
    <div class="pa-4">
        <div class="text-h6 mb-3">Connections</div>
        <div v-if="checkingAuth">
            <content-loader height="40"></content-loader>
        </div>
        <div v-else>
            <div class="d-flex align-center" v-if="connected">
                <v-icon color="#00a1e0" left>mdi-check-circle</v-icon>
                Salesforce Connected
            </div>
            <v-btn v-else dark color="#00a1e0" elevation="0" :loading="connecting" :href="link">
                <v-icon left>mdi-salesforce</v-icon>
                Connect Salesforce Account
            </v-btn>
        </div>
        
    </div>
</template>

<script>
import SalesforceApi from '@/api/SalesforceApi'
import { ContentLoader } from 'vue-content-loader'
import { mapMutations } from 'vuex'
export default {
    components: {
        ContentLoader
    },
    data(){
        return {
            connecting: !!this.$route.query.code || false,
            checkingAuth: false,
            connected: false
        }
    },
    computed: {
        link(){
            return `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_SF_CONSUMER_KEY}&redirect_uri=${window.location.origin}/dashboard/account`
        }
    },
    methods:{
        ...mapMutations(['showSnackBar']),
        async connect(){
            this.connecting = true
            try {
                const res = await SalesforceApi.handleCallback(this.$route.query.code)
                if(res.data){
                    this.connected = true
                    this.showSnackBar({text: 'Salesforce connected successfully', color: 'success', timeout: 2000})
                }
            } catch (error) {
                console.log(error)
            }
            this.connecting = false
        },
        async checkAuth(){
            this.checkingAuth = true
            try {
                const res = await SalesforceApi.checkAuth()
                if(res.data.authenticated){
                    this.connected = true
                }else{
                    this.connected = false
                }
            } catch (error) {
                console.log(error)
            }
            this.checkingAuth = false
        }
    },
    mounted(){
        this.checkAuth()
        if(this.$route.query.code){
            this.connecting = true
            this.connect()
        }
    }
}
</script>