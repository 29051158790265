<template>
    <empty-layout>
        <navigation-back-button></navigation-back-button>
        <v-card class="py-6" outlined>
            <h4 class="text-h4 px-6 mb-6">Account</h4>
            <v-row class="pa-6">
                <v-col>
                    <profile-info v-if="user"></profile-info>
                </v-col>
            </v-row>
            <template v-if="user && user.features.data_mapping">
                <v-divider></v-divider>
                <connections></connections>
            </template>
            <template v-if="user && user.features.api_access">
                <v-divider></v-divider>
                <v-row>
                    <v-col>
                        <ApiToken></ApiToken>
                    </v-col>
                </v-row>
            </template>
        </v-card>
    </empty-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import axios from 'axios'
import ApiToken from './APITokens.vue';
import EmptyLayout from '../../../layouts/EmptyLayout.vue';
import NavigationBackButton from '../../../layouts/NavigationBackButton.vue';
import Api from '../../../api/Api';
import ProfileInfo from './ProfileInfo.vue';
import Connections from './Connections.vue';

export default {
    name: "Account",
    components: {
        ApiToken,
        EmptyLayout,
        NavigationBackButton,
        ProfileInfo,
        Connections,
    },
    data() {
        return {
            errors: {},
            user_data: {
                first_name: '',
                last_name: '',
                email: '',
                password: ''
            }
        };
    },

    watch: {
        user: {
            deep: true,

            handler(val) {
                this.user_data = { ...val }
                if (val.first_name) {
                    this.errors["first_name"] = [];
                }
                if (val.last_name) {
                    this.errors["last_name"] = [];
                }
                if (val.email) {
                    this.errors["email"] = [];
                }
                if (val.password) {
                    this.errors["password"] = [];
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        })
    },
    methods: {
        ...mapActions({
            setUser: "auth/setUser",
        }),

        ...mapMutations(["showSnackBar"]),
        async updateUser() {
            const res = await axios.post(
                "/api/users/auth/update-info",
                this.user_data
            );
            this.user_data.password = '';
            if (res.data.errors) {
                this.errors = res.data.errors;
            }

            if (res.data && res.data.user_info_updated) {
                this.showSnackBar({
                    color: "success",
                    timeout: 3000,
                    text: `Information is updated`,
                });
                this.setUser()

            }
        },


        async sendVerification() {
            try {
                const res = await Api.sendEmailVerification()
                if (res && res.data) {
                    if (res.data.status === 'sent') {
                        this.showSnackBar({
                            color: "success",
                            timeout: 6000,
                            text: `The email has been sent to ${this.user.email}`,
                        });
                    }
                }
            } catch (error) {
                let text = 'Can\'t send email. Please try again later.'
                if (error.response && error.response.data && error.response.data.error) {
                    text = error.response.data.error
                }
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text,
                });
            }

        }
    },
    async mounted() {
        await this.setUser();
    },
};
</script>

<style scoped lang="scss">
.text-input {
    @include text-input;
    grid-gap: 0 !important;

    &__error {
        color: red;
    }
}
</style>
